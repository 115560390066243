import React from "react";
import { graphql, StaticQuery } from "gatsby";
import { RichText as PrismicRichText } from "prismic-reactjs";

import { columnPush } from "utils";
import { H1, PageHeader, Section, SEO, RichText, FadeGroup } from "components";

import { serializer, linkResolver } from "utils";

const LotFinderPage = ({ preview }) => (
  <StaticQuery
    query={graphql`
      {
        prismicLotFinder {
          dataString
          data {
            main_image {
              fluid(maxWidth: 1600) {
                ...GatsbyPrismicImageFluid
              }
            }
          }
        }
      }
    `}
    render={(data) => {
      let page = JSON.parse(data.prismicLotFinder.dataString);
      if (preview) {
        page = preview;
      }
      return (
        <>
          <SEO
            image={page.seo_image && page.seo_image.url}
            title={page.seo_title && PrismicRichText.asText(page.seo_title)}
            description={
              page.seo_description &&
              PrismicRichText.asText(page.seo_description)
            }
          />
          <PageHeader
            bg="brand.nickel"
            src={preview ? page.main_image && page.main_image.url : undefined}
            fluid={
              preview
                ? undefined
                : data.prismicLotFinder.data.main_image &&
                  data.prismicLotFinder.data.main_image.fluid
            }
            alt={page.main_image && page.main_image.alt}
          />
          <Section pt={2} pb={3} bg={["brand.navy", "bg.default"]}>
            <div
              css={`
                -ms-grid-column: 3;
                -ms-grid-column-span: 11;
                grid-column: 2 / 8;
                -ms-grid-row: 1;
                grid-row: 1;
                ${(props) => props.theme.mediaQueries.medium} {
                  -ms-grid-column: 1;
                  -ms-grid-column-span: 15;
                  grid-column: 1 / 9;
                }
                ${(props) => props.theme.mediaQueries.small} {
                  -ms-grid-column: 1;
                  -ms-grid-column-span: 7;
                  grid-column: 1 / 5;
                }
              `}
            >
              <div
                css={`
                  position: relative;
                  ${columnPush(1)};
                  ${(props) => props.theme.mediaQueries.small} {
                    padding-left: 0;
                  }
                `}
              >
                <FadeGroup>
                  <H1
                    children={page.title && PrismicRichText.asText(page.title)}
                    mb="0.5em"
                    color={["text.reverse", "text.default"]}
                  />
                  <RichText
                    children={
                      page.description &&
                      PrismicRichText.render(
                        page.description,
                        linkResolver,
                        serializer
                      )
                    }
                    color={["text.reverse", "text.default"]}
                  />
                </FadeGroup>
              </div>
            </div>
          </Section>
          <Section
            pb={3}
            css={`
              ${(props) => props.theme.mediaQueries.small} {
                padding-bottom: 0;
              }
            `}
          >
            <div
              css={`
                -ms-grid-column: 1;
                -ms-grid-column-span: 23;
                grid-column: 1 / 13;
                -ms-grid-row: 1;
                grid-row: 1;
                ${(props) => props.theme.mediaQueries.medium} {
                  -ms-grid-column: 1;
                  -ms-grid-column-span: 15;
                  grid-column: 1 / 9;
                }
                ${(props) => props.theme.mediaQueries.small} {
                  -ms-grid-column: 1;
                  -ms-grid-column-span: 7;
                  grid-column: 1 / 5;
                  margin: 0 -10vw;
                }
              `}
            >
              <iframe
                src="https://ucmaps.lotworks.ca/maps/wedderburn"
                title="sitemap"
                frameBorder="0"
                css={`
                  width: 100%;
                  height: 100%;
                  min-height: 75vh;
                  vertical-align: top;
                  background-color: ${(props) => props.theme.colors.bg.wash};
                `}
              />
            </div>
          </Section>
        </>
      );
    }}
  />
);

export default LotFinderPage;
